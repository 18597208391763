<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="CUSTOMER"
        :filter="filter"
        placeholder="Search Name, Telephone, Email"
        @search="handleSearch"
        @export="getFilterExport"
        @downloadTemplate="downLoadTemplate"
        @sidebar="sidebarFilter"
      >
        <template v-slot:content-dropdown>
          <b-dropdown-item @click="createUser()">
            <span>Create New Customer</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$router.push('/customer/importdata')">
            <span>Import Customer Data (.xlsx) </span>
          </b-dropdown-item>
          <b-dropdown-item @click="$router.push('/customer/importpoint')">
            <span>Import Adjust Point (.xlsx) </span>
          </b-dropdown-item>
          <b-dropdown-item @click="$router.push('/customer/configdata')">
            <span>Import Config</span>
          </b-dropdown-item>
          <b-dropdown-item @click="exportModal">
            <span>Export</span>
          </b-dropdown-item>
          <b-dropdown-item @click="downLoadTemplate">
            <span>Download Customer Template </span>
          </b-dropdown-item>
          <b-dropdown-item @click="downloadPointTemplate">
            <span>Download Adjust Point Template</span>
          </b-dropdown-item>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <TableListReportCustomer
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :showing="showing"
          :showingTo="showingTo"
          :rows="rows"
          :pageOptions="pageOptions"
          @createUser="createUser()"
          :filter="filter"
          @page="filterPage"
          @handleChangeTake="handleChangeTake"
        />
      </div>
    </div>
    <SidebarFilterReportPanel
      ref="sidebarFilter"
      :filter="filter"
      placeholder="Search Name, Telephone, Email"
      :isConsentList="isConsentList"
      :memberLevelList="memberLevelList"
      :branchList="branchList"
      :birthMonth="birthMonth"
      :AgeGroupFilter="AgeGroupFilter"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
    />
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="getFilterExport"
    />
    <ModalLoading ref="LoadingModal" /> <CreateNewUser notShow ref="newUser" />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalLoading from "@/components/modal/ModalLoading";
import CreateNewUser from "./components/CreateNewUser";
import SidebarFilterReportPanel from "@/components/report/header/SidebarFilterReportPanel";
import TableListReportCustomer from "@/components/report/customer/TableListReportCustomer";
export default {
  components: {
    OtherLoading,
    CreateNewUser,
    SidebarFilterReportPanel,
    TableListReportCustomer,
    ModalLoading,
    ModalInputEmail,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "first_name_th",
          label: "Customer Name",
        },
        {
          key: "member_level_name",
          label: "Tier",
        },
        {
          key: "is_company",
          label: "Type",
        },
        {
          key: "telephone",
          label: "Telephone",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "point_total",
          label: "Point",
        },
        {
          key: "created_time",
          label: "Register Date",
        },
        {
          key: "grand_total",
          label: "Grand Total",
        },
        {
          key: "is_consent",
          label: "Consent Status",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      isBusy: true,
      rows: 0,
      showing: 1,
      showingTo: 10,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        member_level_id: null,
        search: null,
        page: 1,
        take: 10,
        birth_month: null,
        purchase_branch: null,
        is_consent: null,
        AgeGroupFilter: [],
      },
      AgeGroupFilter: [],
      isConsentList: [
        { id: 1, name: "Yes" },
        { id: 2, name: "Wating For Approve" },
        { id: 0, name: "No" },
        { id: 3, name: "Fail Consent 1" },
        { id: 4, name: "Fail Consent 2" },
        { id: 5, name: "Fail Consent 3" },
        { id: 6, name: "Relieve" },
        { id: 7, name: "Suspend" },
      ],
      birthMonth: [],
      branchList: [],
      memberLevelList: [],
      ageGroup: [],
      filterExportFile: {
        member_level_id: null,
        search: null,
        is_consent: null,
        email: "",
        purchase_branch: null,
        birth_month: null,
      },
    };
  },
  async created() {
    console.log(this.$route.params);
    this.getFilter();
    if (this.$route.params.id) this.filter.search = this.$route.params.id;
    await this.getReportCustomer();
    await this.getMemberLevelList();
    let i = 1;
    for (const month of this.$moment.months()) {
      this.birthMonth.push({ text: month, value: i });
      i++;
    }
  },
  methods: {
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    async getFilter() {
      const result = await this.axios(`/transactioncode/branch`);
      let branchList = [];
      for (const branch of result.data.detail) {
        branchList.push({
          text: `[${branch.plant_id}] ${branch.name} `,
          value: branch.id,
        });
      }
      this.branchList = branchList;
      const ageGroup = await this.axios(
        `/RevenueReport/CustomFilter/age_group`
      );

      this.AgeGroupFilter = ageGroup.data;
    },
    clearFilter() {
      this.filter = {
        member_level_id: null,
        search: null,
        page: 1,
        take: 10,
        birth_month: null,
        purchase_branch: null,
        is_consent: null,
        AgeGroupFilter: [],
      };
    },
    async getReportCustomer() {
      this.isBusy = true;
      let payload = { ...this.filter };
      for (const [keys, value] of Object.entries(this.filter)) {
        if (keys == "AgeGroupFilter" && payload[keys]) {
          payload[keys] = value.map((el) => el.id);
        }
      }

      payload.purchase_branch = payload.purchase_branch
        ? payload.purchase_branch.map((el) => el.value)
        : null;
      payload.birth_month = payload.birth_month
        ? payload.birth_month.map((el) => el.value)
        : null;
      payload.search = payload.search ? payload.search.trim() : "";
      await this.$store.dispatch("getReportCustomerList", payload);
      const data = this.$store.state.report.reportCustomerList;
      this.items = data.detail;
      this.rows = data.total_count;
      this.isBusy = false;
    },
    async getMemberLevelList() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevelList = data.detail;
      }
    },
    getFilterExport() {
      let payload = { ...this.filter };
      payload.purchase_branch = payload.purchase_branch
        ? payload.purchase_branch.map((el) => el.value)
        : null;
      payload.birth_month = payload.birth_month
        ? payload.birth_month.map((el) => el.value)
        : null;

      payload.AgeGroupFilter = payload.AgeGroupFilter
        ? payload.AgeGroupFilter.map((el) => el.value)
        : null;

      this.filterExportFile.member_level_id = this.filter.member_level_id;
      this.filterExportFile.search = this.filter.search;
      this.filterExportFile.is_consent = this.filter.is_consent;
      this.filterExportFile.purchase_branch = payload.purchase_branch;
      this.filterExportFile.birth_month = payload.birth_month;
      this.filterExportFile.AgeGroupFilter = payload.AgeGroupFilter;
      this.exportReportCustomer();
    },
    async exportReportCustomer() {
      this.$refs.LoadingModal.show();
      await this.$store.dispatch(
        "exportReportCustomerList",
        this.filterExportFile
      );
      const data = this.$store.state.report.respExportCustomer;
      if (data.status == 200) {
        this.confirmAlert({
          message: "Do you want to download the file now?",
          title: "Success !",
          icon: "success",
          confirmButtonText: "Go To Page",
          cancelButtonText: "Close",
        }).then((val) => {
          if (val.isConfirmed) {
            // this.deleteItem(id);
            const routeData = this.$router.resolve({
              name: "Customer Report List",
            });
            window.open(routeData.href, "_blank");
          }
        });
      } else {
        this.errorAlert(data.message);
      }
      this.$refs.LoadingModal.hide();
    },
    filterPage(filter) {
      this.filter = filter;
      this.getReportCustomer();
    },
    handleSearch(filter) {
      filter.page = 1;
      this.filter = filter;
      this.getReportCustomer();
    },
    searchAll(filter) {
      this.filter = filter;

      this.getReportCustomer();
    },
    handleChangeTake(filter) {
      this.filter = filter;
      this.getReportCustomer();
    },
    exportModal() {
      this.getFilterExport();
    },
    async downLoadTemplate() {
      this.isLoading = true;
      await this.$store.dispatch("downloadTemplateCustomer");
      const data = this.$store.state.importFile.downloadCustomer;
      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      this.isLoading = false;
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Customer-Template-List-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async downloadPointTemplate() {
      this.$bus.$emit("showLoading");

      let data = await this.axios(`/import/adjustPointTemplate`, {
        responseType: "blob",
      });
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `customer-adjust-point-template.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.errorAlert("Export Fails");
      }

      this.$bus.$emit("hideLoading");
    },
    createUser() {
      this.$refs.newUser.createUser();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-td-checkbox {
  width: 5%;
}
</style>
